.App {
  text-align: center;
}

.App-logo {
  height: 13vmin;
  pointer-events: none;
  margin: 10px;
}

.App-img {
  height: 30vmin;
  pointer-events: none;
}

.Box {
  padding: 2em;
  background-color: #222327;
  width: 80vmin;
  height: 60vmin;
  overflow: scroll;
  font-size: 10px;
  align-items: left;
  justify-content: left;
  text-align: left;
}

.pa-10 {
  padding: 1em;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  padding: 2em;
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
  overflow: hidden;
  background-color: rgb(26, 27, 46);
}
p,a {
  font-size: calc(8px + 1vmin);
}

.p1 {
  font-size: calc(9px);
}

li {
  float: left;
}

li a {
  display: block;
  color: white;
  text-align: center;
  padding: 6px 8px;
  text-decoration: none;
  font-size: calc(8px + 2vmin);
}

/* Change the link color to #111 (black) on hover */
li a:hover {
  background-color: rgb(61, 46, 61);
}
@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
